import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout/Layout';

const TermsCondition = () => {
  return (
    <Layout title={"easyjackets-Privacy policy"}>
    <Container className="my-5">
      <Row>
        <Col>
      <h1 className="text-3xl font-bold mb-6 text-center">Terms & Conditions</h1>
      
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Limitation Of Liability</h3>
        <p className="mt-2 text-gray-700">
          EasyJackets, it’s affiliates and related companies take no responsibility for the accuracy or validity of any claims or statements contained in the information or related graphics of this site. Further, EasyJackets makes no representations whatsoever about the suitability of any of the information contained in the information or graphics on this site for any purpose. All such information and related graphics are provided without warranty of any kind. EasyJackets makes no representations whatsoever about any other Web site which you may access through this site. When you access a non- EasyJackets Web site, you understand and acknowledge that it is independent from EasyJackets and that EasyJackets has no control over the content on any such Web site. In addition, a link to a non- EasyJackets Web site does not mean that EasyJackets endorses or accepts any responsibility for the content, or the use, of such Web site. It is your responsibility to take precautions to ensure that whatever you select for your use by you is free of such items such as viruses, worms, trojan horses, and other items of a destructive nature.
          IN NO EVENT WILL EASY JACKETS BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE INFORMATION AVAILABLE OR REFERRED TO ON THIS SITE.
        </p>
      </section>
      
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Use Of the Site</h3>
        <p className="mt-2 text-gray-700">
          While visiting the EasyJackets.com web site you may not: post, upload, publish, reproduce, transmit, or otherwise distribute information or other material: (a) constituting or encouraging conduct that would constitute a criminal offense or give rise to civil liability; (b) which is protected by copyright, or other intellectual property right, or derivative works with respect thereto, without obtaining permission of the copyright owner or rightholder; (c) otherwise use this site in a manner which is contrary to law, including the posting or transmitting of any information or software which contains a virus, cancelbot, trojan horse, worm or other harmful or disruptive component.
        </p>
      </section>
      
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Copyright</h3>
        <p className="mt-2 text-gray-700">
          All materials published or otherwise accessible through this site including, but not limited to, news articles, text, photographs, images, illustrations, audio clips, video clips, software, and other materials (the “Content”) are protected by copyright, and are owned or controlled by EasyJackets and any others which may own copyright or the party credited as the provider of the Content. All visitors shall abide by all additional copyright notices, information, and restrictions contained in the Content. All visitors shall abide by all additional copyright notices, information, and restrictions contained in any content accessed through this site.
        </p>
      </section>
      
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Cancellation Policy</h3>
        <p className="mt-2 text-gray-700">
          We cut and sew jacket based on the information received. If you are not happy with the order you can change or cancel the order within 24 hours of placing the order otherwise the order cannot be CANCELLED or REFUND.
        </p>
      </section>
      
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Usage Of site</h3>
        <p className="mt-2 text-gray-700">
          You cannot use copyright LOGOS of brands, teams and schools without prior permission from the owner of the brand. In case of copyright infringement, the user will be responsible for providing the documents of approval to the specific brand. We can cancel your order at any time if we found copyright infringement issues on your order.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Portfolio Of the Site</h3>
        <p className="mt-2 text-gray-700">
          We work with many individuals / brands / companies. We keep the right to showcase our work on our website / social network accounts. If you don’t want your stuff to be published please let us know in advance.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Trademark</h3>
        <p className="mt-2 text-gray-700">
        The trademarks, logos, EasyJackets” and other names, logos, and icons identifying the products and services (collectively the “trademarks”) displayed on the site, are registered and unregistered trademarks of EasyJackets and others. Nothing contained on the site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark displayed on the site without the written permission of EasyJackets or such third party that may own the trademarks displayed on the Site. Your misuse of the trademarks displayed on the site, or any other content on the site, except as provided in these Legal Terms of Use, is strictly prohibited. You are also advised that EasyJackets will aggressively enforce its intellectual property rights to the fullest extent of the law, including the seeking of criminal prosecution. If someone will use someone/brands intellectual property in terms of their trademark / logo, he/she will be responsible for his/her act. As an offshore company we are not aware what belongs to where. EasyJackets / EasyJackets.com will not held responsible for any copyright stuff website user upload.       </p>
      </section>

      <section className="mb-4">
      <h3 className="text-xl font-semibold">Confidential Information</h3>
      
You authorize EasyJackets.com to collect from any party and to retain all relevant information relating to your use of this site, and you hereby authorize any party to provide us with such information. You understand and agree that unless you notify EasyJackets to the contrary by e-mailing us, or writing to us using the address provided above you further authorize EasyJackets to disclose, on a confidential basis, to any necessary parties with whom EasyJackets has business relations all necessary information relating to your dealings with us and this site.
</section>
<section className="mb-4">
      <h3 className="text-xl font-semibold">  Recourse</h3>
    
      If you are dissatisfied with the EasyJackets.com site or with any terms, conditions, rules, policies, guidelines, or practices of EasyJackets / EasyJackets.com in operating this site, your sole and exclusive remedy is to discontinue using this site.</section>
<section className="mb-4">
      <h3 className="text-xl font-semibold">  Indemnity</h3>
      
You agree to defend, indemnify, and hold EasyJackets / EasyJackets.com and its affiliates and related companies harmless from any and all liabilities, costs, and expenses, including reasonable attorneys’ fees, related to the violation of this Agreement by you or users of your account, or in connection with the use of this site or the placement or transmission of any information or other materials on this site by you or users of your account.

</section>

<section className="mb-4">
      <h3 className="text-xl font-semibold">  Miscellaneous</h3>
    
This Agreement, including any and all documents referenced herein, constitutes the entire agreement between EasyJackets / EasyJackets.com and you pertaining to the subject matter hereof. EasyJackets / EasyJackets.com’ failure to insist upon or enforce strict performance of any provision of this Agreement shall not be construed as a waiver of any provisions or right. If any of the provisions contained in this Agreement be determined to be void, invalid, or otherwise unenforceable by a court of competent jurisdiction, such determination shall not affect the remaining provisions contained herein. This Agreement shall be governed by and construed in accordance with the laws of the state of International Laws applicable therein. The parties have requested that this Agreement and all documents relating thereto be drawn up in English.
EasyJackets / EasyJackets.com has no obligation to monitor this site. However, you agree that EasyJackets / EasyJackets.com has the right to monitor this site electronically from time to time and to disclose any information as necessary to satisfy any law, regulation, or other governmental request, to operate this site properly, or to protect itself or its subscribers. EasyJackets / EasyJackets.com reserves the right to refuse to post or to remove any information or materials, in whole or in part, that, in its sole discretion, are unacceptable, undesirable, or in violation of this Agreement.
</section>

      </Col>
    </Row>
    </Container>
    </Layout>
    
  );
};

export default TermsCondition;
